@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'FMBolyarSansPro';
  font-weight: 100;
  src: url('./assets/fonts/FM_Boylar_font/FMBolyarSansPro-100.ttf') format('truetype');
}

@font-face {
  font-family: 'FMBolyarSansPro';
  font-weight: 200;
  src: url('./assets/fonts/FM_Boylar_font/FMBolyarSansPro-200.ttf') format('truetype');
}

@font-face {
  font-family: 'FMBolyarSansPro';
  font-weight: 300;
  src: url('./assets/fonts/FM_Boylar_font/FMBolyarSansPro-300.ttf') format('truetype');
}

@font-face {
  font-family: 'FMBolyarSansPro';
  font-weight: 400;
  src: url('./assets/fonts/FM_Boylar_font/FMBolyarSansPro-400.ttf') format('truetype');
}

@font-face {
  font-family: 'FMBolyarSansPro';
  font-weight: 500;
  src: url('./assets/fonts/FM_Boylar_font/FMBolyarSansPro-500.ttf') format('truetype');
}

@font-face {
  font-family: 'FMBolyarSansPro';
  font-weight: 600;
  src: url('./assets/fonts/FM_Boylar_font/FMBolyarSansPro-600.ttf') format('truetype');
}

@font-face {
  font-family: 'FMBolyarSansPro';
  font-weight: 700;
  src: url('./assets/fonts/FM_Boylar_font/FMBolyarSansPro-700.ttf') format('truetype');
}

@font-face {
  font-family: 'FMBolyarSansPro';
  font-weight: 800;
  src: url('./assets/fonts/FM_Boylar_font/FMBolyarSansPro-800.ttf') format('truetype');
}

